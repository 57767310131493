<template>
  <div class="progress">
    <BossCard
      v-for="i in '12345'"
      :key="i"
      :name="i"
      :round="prog.round[i - 1]"
      :hp="prog.hp[i - 1]"
      :max_hp="prog.max_hp[i - 1]"
    />
  </div>
</template>

<script>
import BossCard from '@/components/BossCard.vue'
import DataService from '@/services/DataService'

export default {
  name: 'Progress',
  components: { BossCard },
  data() {
    return {
      prog: {
        round: '-',
        hp: ['-', '-', '-', '-', '-'],
        max_hp: ['-', '-', '-', '-', '-'],
      },
    }
  },
  created() {
    this.$emit('changeGid', this.$route.params.gid)
    DataService.getProgress(this.$route.params.gid)
      .then((resp) => {
        this.prog = resp.data.prog
      })
      .catch((error) => {
        console.error(error)
      })
  },
  props: ['gid'],
  emits: ['changeGid'],
}
</script>

<style scoped>
.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
