<template>
  <div class="boss-card" @click="show_detail = !show_detail">
    <h4>
      Boss {{ name }} | {{ round }} 周目 <br />
      {{ hp }} / {{ max_hp }}
    </h4>
    <div v-show="show_detail">detail</div>
  </div>
</template>

<script>
export default {
  name: 'BossCard',
  data() {
    return {
      show_detail: false,
    }
  },
  props: ['name', 'round', 'hp', 'max_hp'],
}
</script>

<style scoped>
.boss-card {
  margin: 0.2em;
  padding: 0.5em;
  height: auto;
  width: 80%;
  max-width: 30em;
  cursor: pointer;
  border: 0.1em solid #39495c;
}
.boss-card:hover {
  transform: scale(1.02);
  box-shadow: 0 0.3em 1.2em rgba(0, 0, 0, 0.2);
}
</style>
